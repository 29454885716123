<template>
  <div>
    <!-- 协议管理 -->
    <!-- <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-input v-model="queryParams.systemTreatyName" clearable placeholder="请输入协议名称" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.systemTreatyManager" clearable placeholder="请输入协议负责人" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
    </el-form> -->
    
    <div class="content-wrap">
      <div class="btn-wrap cfx">
        <el-button v-if="OA.includes('jcxx:xygl:add')" type="success" size="small" icon="el-icon-plus" @click="handleForm(false)">添加</el-button>
      </div>
      <div v-if="treatyTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <!--列表-->
      <paged-table :data="treatyList" :total="treatyTotal" :loading="tLoading" v-if="treatyTotal>0"
        :isShowSelection="false" defaultSortProp="id" v-model="queryParams"
        @refresh="refresh">
        <el-table-column sortable="custom" prop="name" show-overflow-tooltip label="协议名称"></el-table-column>
        <el-table-column sortable="custom" prop="type" show-overflow-tooltip label="协议类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type==1">注册协议</span>
            <span v-if="scope.row.type==2">隐私协议</span>
          </template>
        </el-table-column>
        <el-table-column align="right" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button v-if="OA.includes('jcxx:xygl:load')" size="mini" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
            <el-button v-if="OA.includes('jcxx:xygl:editor')" size="mini" type="primary" @click="handleForm(true, scope.row)" icon="el-icon-edit" title="编辑"></el-button>
            <el-button v-if="OA.includes('jcxx:xygl:del')" size="mini" type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除"></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑协议' : '添加协议'" :visible.sync="aVisible" width="800px" :close-on-click-modal="false">
      <el-form label-width="120px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="协议名称：" prop="name" style="width: 80%">
          <el-input placeholder="请输入协议名称" v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="协议类型" prop="type" style="width: 80%" >
          <el-select v-model="form.type" clearable filterable placeholder="请选择协议类型">
            <el-option label="注册协议" :value="1"></el-option>
            <el-option label="隐私协议" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="协议内容：" prop="content">
          <tinymce placeholder="请输入协议内容" v-model="form.content" v-if="aVisible"></tinymce>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

    <!-- 详情界面 -->
    <el-dialog title="协议详情" :visible.sync="bVisible" width="550px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="协议名称：" prop="name">
          <span v-text="details.name"></span>
        </el-form-item>
        <el-form-item label="协议内容：" prop="content">
          <span v-html="details.content"></span>
        </el-form-item>
        <el-form-item label="协议类型：" prop="type">
          <span>{{ details.type==1?'注册协议':'隐私协议' }}</span>
        </el-form-item>
        <el-form-item label="协议添加人：" prop="extend.addUserName">
          <span v-text="details.extend.addUserName"></span>
        </el-form-item>
        <el-form-item label="协议添加时间：" prop="addTime">
          <span v-text="details.addTime"></span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import datePicker from "@/mixins/datePicker";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
import Tinymce from "@/tinymce";
export default {
  name:'treatyManage',
  components: {
    PagedTable,
    Tinymce,
  },
  mixins: [pageMixin, datePicker],
  data() {
    return {
      queryParams: {
        systemTreatyName: null,
        systemTreatyManager: null,
      },

      form:{
        id:'',
        name: '',
        content: '',
        type: '',
      },
      details:{
        name:'',
        content:'',
        type:'',
        addTime:'',
        extend: {
          addUserName:'',
        },
      },
      formRules:{
        name: [{ required: true, message: '请输入协议名称', trigger: 'blur' }],
        content: [{ required: true, message: '请输入协议内容', trigger: 'blur' }],
        type: [{ required: true, message: '请选择协议类型', trigger: 'blur' }],
      },

      pageApi:'systemTreatyList',
      insertApi:'systemTreatyAdd',
      editorApi:'systemTreatyEdit',
      deleteApi:'systemTreatyDelete',
      loadApi:'systemTreatyLoad',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("systemTreaty", ["treatyList", "treatyTotal"]),
  },
  methods: {
    ...mapActions("systemTreaty",["systemTreatyList", "systemTreatyAdd", "systemTreatyEdit", "systemTreatyDelete", "systemTreatyLoad"]),
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
  }
};
</script>
